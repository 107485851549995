import React from 'react'
import Media from 'react-bootstrap/Media'
import Image from 'react-bootstrap/Image'
import Truncate from 'react-truncate'

export default class CommentCell extends React.Component { 

	static defaultProps = {
		imageSize: 40,
		usernameFontSize: 16,
		messageFontSize: 16
	}


	getUserPhoto() { 
		const noImageUrl = "/static/person.png";
		const comment = this.props.comment;
		if (comment.creator.profile_image) { 
			const commentImageUrl = comment.creator.profile_image.image;
			if (commentImageUrl) { 
				return comment.creator.profile_image.image;
			}
		}
		return noImageUrl;
	}

	getValue() { 
		if (this.props.truncate) { 
			return (<Truncate lines={2} ellipsis={<span>...</span>}>
                {this.props.comment.value}
            </Truncate>)
		} else {
			return <p style={{fontSize: this.props.messageFontSize}}>{this.props.comment.value}</p>
		}
	}

	render() { 
		const comment = this.props.comment
		return (
			<Media style={this.props.style}>
			  <Image
			    width={this.props.imageSize}
			    height={this.props.imageSize}
			    className="mr-3"
			    src={this.getUserPhoto()}
			    alt="Generic placeholder"
			  	roundedCircle/>
			  <Media.Body>
			    <p style={{color:'gray', marginBottom:5, fontSize: this.props.usernameFontSize, wordBreak: 'break-all'}}>{this.props.comment.creator.username}</p>
			    {this.getValue()}
			  </Media.Body>
			</Media>
			)
	}
}